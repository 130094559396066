import React from "react"
import UniversalLink from "../components/universal-link"
import { css } from "@emotion/core"

export default function AndroidInclude(props) {
  return (<>
      <hr css={css`
        margin-top: 40px;
      `}/>
      <p>
        Parts of this documentation are a derivate of the <UniversalLink to="https://developer.android.com">Android
        Developer Documentation</UniversalLink> by Google used under <UniversalLink
        to="https://creativecommons.org/licenses/by/2.5/">CC-BY-2.5</UniversalLink>.
      </p>
      <hr/>
    </>
  )
}
