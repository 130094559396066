import React, { useState } from "react"
import PropTypes from "prop-types"
import {Button} from '@apollo/space-kit/Button';

/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import UniversalLink from "../components/universal-link"


const videoWrapper = css`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 20px 0;
`

const innerContentStyle= css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const overlayStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: rgba(255,255,255,0.94);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
`

const Video = ({ id }) => {

  const [allowedCookie, setAllowedCookie] = useState(false)

  const options = {
    height: "315",
    width: "560",
    frameBorder: 0,
  }

  const playerVars = { // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    showinfo: 0,
    modestbranding: 1,
    controls: 2,
  }

  const playerVarString = () => {
    let result = ""
    Object.keys(playerVars).map(varKeys => {
      result += ("?" + varKeys + "=" + playerVars[varKeys])
    })
    return result
  }


  const _confirmCookie = () => {
    setAllowedCookie(true)
  }


  return (
    <div css={videoWrapper}>
      {
        !allowedCookie ?
        <img css={innerContentStyle} src={`https://img.youtube.com/vi/${id}/maxresdefault.jpg`} alt={'YouTube Video'}/>
        :
        <iframe
          css={innerContentStyle}
          id={"ytplayer"}
          src={`https://www.youtube-nocookie.com/embed/${id}${playerVarString()}`}
          {...options}
          frameBorder="0"
          allowFullScreen/>
      }
      {
        !allowedCookie && <div css={overlayStyle}>
          <p>By clicking play, this video will be embedded from Youtube. The <UniversalLink to={'https://policies.google.com/privacy'}>privacy policies of google</UniversalLink> apply.</p>

          <Button size="large" onClick={_confirmCookie}>Accept</Button>
        </div>
      }
    </div>

  )
}

Video.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Video
