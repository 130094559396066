import React from "react"

const Screenshots = ({ superclass, attributes }) => {
  return (
    <div>
    <table>
      <colgroup>
        <col style={{width: "20%"}} />
        <col style={{width: "20%"}} />
        <col style={{width: "10%"}} />
        <col style={{width: "10%"}} />
        <col style={{width: "40%"}} />
      </colgroup>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Default</th>
          <th>Binding</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="5">
            <p>
              <strong>
                Inherits all properties from <a href={`/components/${superclass}/`}>{superclass}</a>.
              </strong>
            </p>
          </td>
        </tr>
        {attributes.map((value, i) => {              
           return (
            <tr>
          <td><p><strong>{value.name}</strong></p></td>
          <td><p>{value.type}</p></td>
          <td><p>{value.default}</p></td>
          <td>{value.binding}</td>
          <td>{value.description}</td>
        </tr>
           ) 
        })}
       
      </tbody>
    </table>
    </div>
  )
}
export default Screenshots
