import PropTypes from "prop-types"
import {myVars} from "../variables"

export default function MyVar(props) {
  const {name} = props;

  return myVars[name] ? myVars[name] : `!!! ${name} is missing in variables.js !!!`
}

MyVar.propTypes = {
  name: PropTypes.oneOf(Object.keys(myVars)),
};
