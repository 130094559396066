import CodeBlock from "gatsby-theme-apollo-docs/src/components/code-block"
import {
  ExpansionPanel,
  ExpansionPanelList,
  ExpansionPanelListItem,
} from "gatsby-theme-apollo-docs/src/components/expansion-panel"
import CustomSEO from "gatsby-theme-apollo-docs/src/components/custom-seo"
import { MultiCodeBlock } from "./multi-code-block"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import PageContent from "gatsby-theme-apollo-docs/src/components/page-content"
import PageHeader from "gatsby-theme-apollo-docs/src/components/page-header"
import PropTypes from "prop-types"
import React, { Fragment, createContext, useContext } from "react"
import RehypeReact from "rehype-react"
import styled from "@emotion/styled"
import { ContentWrapper, colors, smallCaps } from "gatsby-theme-apollo-core"
import { MDXProvider } from "@mdx-js/react"
import { TypescriptApiBoxContext } from "gatsby-theme-apollo-docs/src/components/typescript-api-box"
import { graphql, navigate } from "gatsby"
import MyVar from "../../mdx/my-var"
import Video from "../../mdx/video"
import AndroidInclude from "../../mdx/android-include"
import { css, Global } from "@emotion/core"
import Screenshots from "../../mdx/screenshots"
import PurpleBatch from "../../mdx/purple-batch"
import BlackBatch from "../../mdx/black-batch"
import UncontrolledLottie from "../../mdx/UncontrolledLottie"


const globalStyles = css`
  .inline {
    display: flex;
    align-items: center;
    
        
    &.top {
      align-items: flex-start;
    }
    
    &.center {
      justify-content: center;
    }
    
    &.around {
      justify-content: space-around;
    }
    
    &.between {
      justify-content: space-between;
    }
    
    &.evenly {
      justify-content: space-evenly;
    }
    
    &.withSpace {
      > span {
        margin-left: 20px !important;
        margin-right: 20px !important;
      }
    }
  }
  
  .inline > span {
    display: inline-flex !important;
    flex: 1;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`

const StyledContentWrapper = styled(ContentWrapper)({
  paddingBottom: 0,
})

const CustomLinkContext = createContext()

function CustomLink(props) {
  const { pathPrefix, baseUrl } = useContext(CustomLinkContext)

  const linkProps = { ...props }
  if (props.href) {
    if (props.href.startsWith("/")) {
      linkProps.onClick = function handleClick(event) {
        const href = event.target.getAttribute("href")
        if (href.startsWith("/")) {
          event.preventDefault()
          navigate(href.replace(pathPrefix, ""))
        }
      }
    } else if (!props.href.startsWith("#") && !props.href.startsWith(baseUrl)) {
      linkProps.target = "_blank"
      linkProps.rel = "noopener noreferrer"
    }
  }

  return <a {...linkProps} />
}

CustomLink.propTypes = {
  href: PropTypes.string,
}

const TableWrapper = styled.div({
  overflow: "auto",
  marginBottom: "1.45rem",
})

const tableBorder = `1px solid ${colors.divider}`
const StyledTable = styled.table({
  border: tableBorder,
  borderSpacing: 0,
  borderRadius: 4,
  [["th", "td"]]: {
    padding: 16,
    borderBottom: tableBorder,
  },
  "tbody tr:last-child td": {
    border: 0,
  },
  th: {
    ...smallCaps,
    fontSize: 13,
    fontWeight: "normal",
    color: colors.text2,
    textAlign: "inherit",
  },
  td: {
    verticalAlign: "top",
    code: {
      whiteSpace: "normal",
    },
  },
})

function CustomTable(props) {
  return (
    <TableWrapper>
      <StyledTable {...props} />
    </TableWrapper>
  )
}

const components = {
  pre: CodeBlock,
  a: CustomLink,
  table: CustomTable,
  MultiCodeBlock,
  MyVar,
  Video,
  ExpansionPanel,
  ExpansionPanelList,
  ExpansionPanelListItem,
  AndroidInclude,
  Screenshots,
  PurpleBatch,
  BlackBatch,
  UncontrolledLottie,
}

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components,
}).Compiler

export default function Template(props) {
  const { hash, pathname } = props.location
  const { file, site } = props.data
  const { frontmatter, headings, fields } =
  file.childMarkdownRemark || file.childMdx
  const { title, description, twitterHandle } = site.siteMetadata
  const {
    sidebarContents,
    githubUrl,
    spectrumUrl,
    typescriptApiBox,
    baseUrl,
  } = props.pageContext

  const pages = sidebarContents
    .reduce((acc, { pages }) => acc.concat(pages), [])
    .filter(page => !page.anchor)

  return (
    <Fragment>
      <Global
        styles={globalStyles}
      />
      <CustomSEO
        title={frontmatter.title}
        description={frontmatter.description || description}
        siteName={title}
        baseUrl={baseUrl}
        image={fields.image}
        twitterHandle={twitterHandle}
      />
      <StyledContentWrapper>
        <PageHeader {...frontmatter} />
        <hr/>
        <PageContent
          title={frontmatter.title}
          graphManagerUrl={fields.graphManagerUrl}
          pathname={pathname}
          pages={pages}
          headings={headings}
          hash={hash}
          githubUrl={githubUrl}
          spectrumUrl={spectrumUrl}
        >
          <CustomLinkContext.Provider
            value={{
              pathPrefix: site.pathPrefix,
              baseUrl,
            }}
          >
            {file?.childMdx ? (
              <TypescriptApiBoxContext.Provider value={typescriptApiBox}>
                <MDXProvider components={components}>
                  <MDXRenderer>{file.childMdx.body}</MDXRenderer>
                </MDXProvider>
              </TypescriptApiBoxContext.Provider>
            ) : (
              renderAst(file.childMarkdownRemark.htmlAst)
            )}
          </CustomLinkContext.Provider>
        </PageContent>
      </StyledContentWrapper>
    </Fragment>
  )
}

Template.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
    query TemplatePageQuery($id: String) {
        site {
            pathPrefix
            siteMetadata {
                title
                description
                twitterHandle
            }
        }
        file(id: {eq: $id}) {
            childMarkdownRemark {
                frontmatter {
                    title
                    description
                }
                headings(depth: h2) {
                    value
                }
                fields {
                    image
                    graphManagerUrl
                }
                htmlAst
            }
            childMdx {
                frontmatter {
                    title
                    description
                }
                headings(depth: h2) {
                    value
                }
                fields {
                    image
                    graphManagerUrl
                }
                body
            }
        }
    }
`
